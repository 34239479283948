export const APP_ID = 'TADA-FINDER'
export const APP_ENTITY = 'TADA-FINDER'

export const defaultDevtoolsOptions = {
  name: 'Tada-Finder',
  enabled: localStorage.getItem('devtools') === 'true',
}

export const ROUTER_BASENAME = '/tadafinder'

export const TADA_FINDER_ROUTES = {
  HOME: ROUTER_BASENAME,
}
