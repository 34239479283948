import PERMISSIONS from '@/config/permissions';
import useEnvStore from '@/stores/useEnvStore/useEnvStore';
import { EnvConfig } from '@/types/common.types';
import { useAuthenticationService, useHasPermission } from 'admin-portal-shared-services';
import { useEffect } from 'react';

const useLoadEnvs = (envs: EnvConfig, defaultCountry: string) => {
  const { SUPPORTED_COUNTRIES } = envs;
  const authentication = useAuthenticationService();
  const { setEnv, setAuthorizedCountry, country, setCountry } = useEnvStore(
    ({ setEnv, setAuthorizedCountry, country, setCountry }) => ({
      setEnv,
      setAuthorizedCountry,
      country,
      setCountry,
    }),
  );

  //Set envs
  useEffect(() => {
    const bearerToken = localStorage.getItem('authHeader') ?? '';
    setEnv({ ...envs, defaultCountry, bearerToken });
  }, [defaultCountry]);

  //Set authorized countries
  const canAccessAllCountries = useHasPermission(PERMISSIONS.ALL_COUNTRIES);

  useEffect(() => {
    if (!authentication || !SUPPORTED_COUNTRIES?.length) return;
    const authorizedCountries = SUPPORTED_COUNTRIES.filter(
      (c) => canAccessAllCountries || authentication.getSupportedCountries().includes(c),
    );
    setAuthorizedCountry(authorizedCountries);
    if (!authorizedCountries.includes(country)) {
      setCountry(authorizedCountries[0]);
    }
  }, [SUPPORTED_COUNTRIES, authentication, canAccessAllCountries, country]);
};

export default useLoadEnvs;
