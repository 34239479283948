import { FEATURE_TOGGLE_V2_PROJECT_NAME } from '@/config/featureToggles';
import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services';

let isInitialized = false;

const useCreateFeatureToggleService = (optimizelyKey: string) => {
  if (!isInitialized) {
    createFeatureToggleServiceV2(FEATURE_TOGGLE_V2_PROJECT_NAME, optimizelyKey);
    isInitialized = true;
  }
};

export default useCreateFeatureToggleService;
