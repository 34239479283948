import { ORCHESTRATOR_LOWER_ENV_URL, ORCHESTRATOR_PROD_URL } from '@/config/services';
import { randomUUIDV4 } from '@/utils/stringUtils';
import { ApolloClient, ApolloLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { useEnvService } from 'admin-portal-shared-services';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
/**
 * Get the final Orchestrator GraphQL URL
 * @param {string} country
 * @returns Orchestrator GraphQL URL
 */
export const orchestratorGraphqlUrl = (country: string): string => {
  const environment = useEnvService().getEnv().toLowerCase();
  let orchestratorUrl = ORCHESTRATOR_LOWER_ENV_URL;
  switch (environment) {
    case 'dev':
      orchestratorUrl = orchestratorUrl.replace('ENV', environment);
      break;
    case 'qa':
      orchestratorUrl = orchestratorUrl.replace('ENV', 'sit');
      break;
    case 'uat':
      orchestratorUrl = orchestratorUrl.replace('ENV', environment);
      break;
    default:
      orchestratorUrl = ORCHESTRATOR_PROD_URL;
      break;
  }
  orchestratorUrl = orchestratorUrl.replace('COUNTRY', country);
  return `${orchestratorUrl}`;
};
/**
 * Generate httpLink for Apollo Client
 * @param country
 * @returns ApolloLink
 */
export const orchestratorLink = (country: string) => {
  const apolloLink: ApolloLink = createUploadLink({
    uri: orchestratorGraphqlUrl(country),
  }) as unknown as ApolloLink;
  return apolloLink;
};
/**
 * Custom auth middleware for Appolo client
 */
export const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: localStorage.getItem('authHeader') ?? '',
      requestTraceId: randomUUIDV4(),
    },
  }));
  return forward(operation);
});
/**
 * Generate new client on every request
 * @param { string } country
 * @returns Apollo client
 */
export const getApolloClient = (country: string): ApolloClient<NormalizedCacheObject> => {
  const client = new ApolloClient({
    link: authMiddleware.concat(orchestratorLink(country)),
    cache: new InMemoryCache({ addTypename: false }),
  });
  return client;
};
