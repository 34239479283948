import { LoadingDots } from '@hexa-ui/components';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTER_BASENAME } from './config/routes';

const Router = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path={`${ROUTER_BASENAME}/`}
        element={
          <Suspense fallback={<LoadingDots />}>
            <h1>Hello World! This is Tada Finder</h1>
          </Suspense>
        }
      />
    </Routes>
  );
};
export default Router;
