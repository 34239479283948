import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  SIDE_MENU: {
    HOME: 'Home',
  },
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  VERSION: {
    VERSION_APP: 'App Version',
    SERVER: 'Server',
    RIGHTS_RESERVED: 'All rights reserved',
  },
};

export default enUS;
